.menu-container {
  padding: 16px;
  background-color: #f8f8f8;
}

.menu-section {
  margin-bottom: 20px;
}

.section-title {
  font-size: 24px;
  font-weight: bold;
  color: #333;
  margin-bottom: 10px;
}

.menu-card {
  display: flex;
  flex-direction: row;
  background-color: #fff;
  border-radius: 8px;
  box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1);
  margin-bottom: 12px;
  overflow: hidden;
}

.menu-card-content {
  display: flex;
  flex-direction: row;
  width: 100%;
}

.menu-text {
  flex: 1;
  padding: 12px;
}

.menu-title {
  font-size: 18px;
  font-weight: 600;
  margin-bottom: 6px;
}

.menu-description {
  font-size: 14px;
  color: #666;
  margin-bottom: 8px;
}

.menu-price {
  font-size: 16px;
  font-weight: bold;
  color: #111;
}

.menu-image-container {
  width: 120px;
  height: 120px;
  overflow: hidden;
}

.menu-image {
  width: 100%;
  height: 100%;
  object-fit: cover;
}

.loading,
.error {
  text-align: center;
  font-size: 18px;
  margin-top: 20px;
  color: #555;
}
