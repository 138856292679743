.menu-card {
  display: flex;
  flex-direction: row;
  background-color: #fff;
  border-radius: 8px;
  box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1);
  margin-bottom: 12px;
  overflow: hidden;
  height: 120px; /* Ensure the card height is fixed */
}

.menu-card-content {
  display: flex;
  flex-direction: row;
  width: 100%;
  height: 100%; /* Make content take full height of the card */
}

.menu-text {
  flex: 1;
  padding: 12px;
  display: flex;
  flex-direction: column;
  justify-content: center;
}

.menu-title {
  font-size: 18px;
  font-weight: 600;
  margin-bottom: 6px;
}

.menu-description {
  font-size: 14px;
  color: #666;
  margin-bottom: 6px;
  display: -webkit-box; /* Truncate for 1 line */
  -webkit-line-clamp: 1; /* Number of lines to truncate */
  -webkit-box-orient: vertical; /* Specifies box orientation */
  overflow: hidden;
  text-overflow: ellipsis;
}

.menu-price {
  font-size: 16px;
  font-weight: bold;
  color: #da1818;
}

.menu-image-container {
  width: 120px; /* Match the width to the card's height for a square image */
  height: 120px; /* Match container height to card height */
  overflow: hidden;
  flex-shrink: 0; /* Prevent the container from shrinking */
}

.menu-image {
  width: 100%; /* Make image width fill its container */
  height: 100%; /* Make image height fill its container */
  object-fit: cover; /* Ensure the image scales proportionally */
  display: block; /* Ensure the image behaves as a block-level element */
}
